import React, { useEffect } from 'react';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';

import { useNavigate } from 'react-router-dom';

import { UserState } from './loginContext';

const Header = () => {
    const { user, opnSide, setOSide } = UserState();

    const navigate = useNavigate();

    useEffect(() => {
        if(user===null){
            navigate('/');
        }
    }, [user]);

    return (
        <div style={{
            backgroundColor: 'navy', color: 'gold',
            height: '10vh', paddingTop: '0px',
            marginTop: '0px', display: 'flex',
        }}>
            <div style={{
                position: 'absolute', 
                left: '1vw', top: '2vh',
            }}>
                <Button 
                    onClick={() => setOSide(!opnSide)}
                    icon={<i className='pi pi-bars' style={{fontSize: '19px'}}></i>} 
                    style={{
                        border: '2px solid gold', padding: '5px',
                        color: 'gold', backgroundColor: 'navy', 
                    }} 
                />
            </div>

            <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', fontSize: '25px', 
                fontWeight: '600', width: '100%',
            }}>
                Telangana Nijam Editor
            </div>

            <div style={{
                position: 'absolute', 
                right: '3vw', top: '1.25vh'
            }}>
                <Avatar 
                    icon="pi pi-user" 
                    size="large" shape='circle' 
                    style={{backgroundColor: 'gold', color: 'navy'}} 
                />
            </div>
        </div>
    );
};

export default Header;