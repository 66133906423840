import React from 'react';

import Load from './load.gif';

export default function Loading(){
    return(
        <div style={{
            display: 'flex', justifyContent: 'center',
            alignItems: 'center', backgroundColor: '#00000050',
            position: 'absolute', zIndex: 1500,
            width: '100%', height: '100%', top: '-5px',
        }}>
            <img src={Load} style={{width: '125px', height: '150px'}} />
        </div>
    )
};