export const DOMAIN = process.env.REACT_APP_GLOBAL_DOMAIN?process.env.REACT_APP_GLOBAL_DOMAIN:'https://devapi.telangananijam.com/';

export const APITYPE = process.env.REACT_APP_API_VERSION?process.env.REACT_APP_API_VERSION:'api/v1/';

export const IMG_DOMAIN = process.env.REACT_APP_GLOBAL_IMAGES?process.env.REACT_APP_GLOBAL_IMAGES:'https://hsassetmanagement.blob.core.windows.net/';

export const CONTAINER_NAME = process.env.REACT_APP_GLOBAL_CONTAINER?process.env.REACT_APP_GLOBAL_CONTAINER:'tnpaper-dev/'

export  const IMG_PATH = IMG_DOMAIN + CONTAINER_NAME;

export const NEWS = DOMAIN+APITYPE+'news';
export const USERS = DOMAIN+APITYPE+'adminusers';
export const USERROLES = DOMAIN+APITYPE+'userroles';
export const ARTICLE = DOMAIN+APITYPE+'newsarticles';
export const NEWS_UPLOAD = DOMAIN+APITYPE+'newsimages';
export const LOGIN = DOMAIN+APITYPE+'adminusers/login';
export const LOOKUPCODE = DOMAIN+APITYPE+'lookupcodes';
export const LOOKUPTYPE = DOMAIN+APITYPE+'lookuptypes';
export const STATE_LIST = DOMAIN+APITYPE+'lookupcodes?type=drop&lookupType=STATE_NAME';
export const DISTRICT_LIST = DOMAIN+APITYPE+'lookupcodes?type=drop&lookupType=TELANGANA';
export const LANGUAGE_LIST = DOMAIN+APITYPE+'lookupcodes?type=drop&lookupType=LANGUAGES';
export const USER_TYPE_LIST = DOMAIN+APITYPE+'lookupcodes?type=drop&lookupType=USER_ROLES';
export const ARTICLE_CATEGORY_LIST = DOMAIN+APITYPE+'lookupcodes?type=drop&lookupType=CATEGORY_ARTICLE';