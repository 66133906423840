import React, { useContext, createContext, useState } from 'react';

const userLogin = createContext();

const DataContext = ({ children }) => {
    const [user, setUser] = useState(null);
    const [opnSide, setOSide] = useState(false);

    return <userLogin.Provider value={{user, setUser, opnSide, setOSide}}>
        {children}
    </userLogin.Provider>
};

export default DataContext;

export const UserState = () => {
   return useContext(userLogin);
};