import React, { useState } from 'react';

import axios from 'axios';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { LOGIN } from '../apiurls';

import { UserState } from './loginContext';

export default function Login(){
    const [uname, setUName] = useState('');
    const [pass, setPass] = useState('');

    const [err, setErr] = useState(false);
    const [load, setLoad] = useState(false);

    const { setUser } = UserState();

    const handleSubmit = () => {
        if(uname && pass){
            setErr(false); setLoad(true);

            axios({
                method: 'post',
                url: LOGIN,
                data: {
                    email: uname,
                    password: pass,
                },
                headers: { 'isToken': false }
            }).then((res) => {
                setUser(res.data); setLoad(false);
            }).catch((err) => {
                console.log(err);
            });
        }else{
            setErr(true);
        };
    };

    return (
        <div style={{
            display: 'flex', height: '100vh',
            justifyContent: 'center', alignItems: 'center',
        }}>
            <Card 
                title={<h4 style={{textAlign: 'center', fontWeight: '600'}}>LOGIN</h4>}
                style={{width: '35vw', height: 'auto', backgroundColor: '#ffffff'}} 
            >
                <div className='grid' style={{marginTop: '-3vh'}}>
                    <div className='col-12'>
                        <label htmlFor="username" style={{fontSize: '15px', fontWeight: '600', marginLeft: '5px'}}>Username / Email<span style={{color: 'red'}}>*</span></label>
                        <InputText id="username" value={uname} onChange={(e) => setUName(e.target.value)} style={{width: '100%'}} disabled={load} />
                        {(err===true && uname==='') && <small style={{color: 'red', marginLeft: '5px', fontSize: '13px'}}>Enter your Username - or - Email.</small>}
                    </div>

                    <div className='col-12' style={{marginTop: '5px'}}>
                        <label htmlFor="password" style={{fontSize: '15px', fontWeight: '600', marginLeft: '5px'}}>Password <span style={{color: 'red'}}>*</span></label>
                        <Password value={pass} onChange={(e) => setPass(e.target.value)} toggleMask style={{ width: '100%' }} disabled={load} feedback={false} />
                        {(err===true && pass==='') && <small style={{color: 'red', marginLeft: '5px', fontSize: '13px'}}>Enter your Password.</small>}
                    </div>

                    <div className='col-12' style={{textAlign: 'center', marginTop: '8px'}}>
                        <Button 
                            label="SUBMIT" className="p-button-warning" onClick={() => handleSubmit()} loading={load}
                            style={{width: '25vw', fontSize: '16px', fontWeight: '600', color: 'gold', backgroundColor: 'navy', border: '0px solid'}} 
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};