import React, { useState } from 'react';

import { UserState } from './loginContext';
import { useNavigate } from 'react-router-dom';

const SideMenu = () => {
    const { opnSide } = UserState();

    const navigate = useNavigate();

    const [select, setSelect] = useState('Home');

    const list = [
        {name: 'News', link: '/news'},
        {name: 'Article', link: '/article'},
        {name: 'Users', link: '/users'},
        {name: 'Lookup Type', link: '/lookuptype'},
        {name: 'Lookup Code', link: '/lookupcode'},
    ];

    const handleSelect = (itm) => {
        setSelect(itm.name); navigate(itm.link);
    };

    return (
        opnSide===true && <div style={{backgroundColor: 'navy', zIndex: 100, height: '100%'}}>
            {list.map((it, idx) => <div 
                className={select!==it.name?'listbox':'listboxs'} 
                key={idx} onClick={() => handleSelect(it)}
            >
                {it.name}
            </div>)}
        </div>
    );
};

export default SideMenu;