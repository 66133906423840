import React, { useState, useEffect } from 'react';

// import { InputMask } from 'primereact/inputmask';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
// import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

import axios from 'axios';

import { IMG_PATH, ARTICLE, STATE_LIST, DISTRICT_LIST, ARTICLE_CATEGORY_LIST   } from '../apiurls';
import { UserState } from './loginContext';

import Loading from './loading';

const Article = () => {
    const [stateList, setSList] = useState([]);
    const [articleList, setAList] = useState([]);
    const [districtList, setDList] = useState([]);
    const [categoryList, setCList] = useState([]);

    const [load, setLoad] = useState(false);

    const [sId, setSId] = useState('');
    const [sObj, setObj] = useState('');

    const [cId, setCId] = useState('');
    const [dId, setDId] = useState('');
    const [stId, setSTId] = useState('');
    const [desc, setDesc] = useState('');
    const [date, setDate] = useState(null);
    const [state, setState] = useState('');
    const [title, setTitle] = useState('');
    const [district, setDistrict] = useState('');
    const [category, setCategory] = useState('');

    const [img, setImg] = useState(null);

    const [err, setErr] = useState(false);
    const [open, setOpen] = useState(false);
    const [opnDel, setODel] = useState(false);
    const [opnView, setOView] = useState(false);

    const { user } = UserState();

    const month = [
        'Jan', 'Feb', 'Mar', 'Apr', 
        'May', 'Jun', 'Jul', 'Aug', 
        'Sept', 'Oct', 'Nov', 'Dec',
    ];

    const header = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="text-xl text-900 font-bold clr-gold">Articles</div>
            
            <Button 
                style={{ width: '100px' }} icon={<i className='pi pi-plus' style={{fontSize: '18px', fontWeight: '600'}}></i>}
                raised severity='warning' onClick={() => setOpen(true)}
                label={<div style={{fontSize: '18px', fontWeight: '600'}}>Add</div>}
            />
        </div>
    );

    const getArticles = () => {
        setLoad(true);

        axios({
            method: 'get',
            url: ARTICLE,
        }).then((res) => {
            setAList(res.data.rows); 
            setLoad(false);
        }).catch((err) => {
            console.log(err);
        });

        axios({
            method: 'get',
            url: STATE_LIST,
        }).then((res) => {
            setSList(res.data);
        }).catch((err) => {
            console.log(err);
        });

        axios({
            method: 'get',
            url: DISTRICT_LIST,
        }).then((res) => {
            setDList(res.data);
        }).catch((err) => {
            console.log(err);
        });
        
        axios({
            method: 'get',
            url: ARTICLE_CATEGORY_LIST,
        }).then((res) => {
            setCList(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleSubmit = () => {
        if(state && district && title && desc && category && date && img){
            setErr(false); setLoad(true);

            let obj = {
                "state_id": stId,
                "district_id": dId,
                "title": title,
                "description": desc,
                "category_id": cId,
                "date": date,
                "category_name": category,
                "state_name": state,
                "district_name": district,
                "updated_by": user.data.id,
            };

            const forms = new FormData();
            forms.append('newsarticleImages', img);

            if(sId===''){
                obj.created_by = user.data.id;

                axios({
                    method: 'post',
                    url: ARTICLE,
                    data: obj,
                }).then((res) => {

                    axios({
                        method: 'put',
                        url: ARTICLE+'/'+res.data.data.id,
                        data: forms,
                    }).then((res) => {
                        handleClose(); getArticles();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            }else{
                axios({
                    method: 'put',
                    url: ARTICLE +'/'+sId,
                    data: obj,
                }).then((res) => {
                    axios({
                        method: 'put',
                        url: ARTICLE+'/'+sId,
                        data: forms,
                    }).then((res) => {
                        handleClose(); getArticles();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            };
        }else{
            setErr(true); setLoad(false);
        };
    };

    useEffect(() => {
        getArticles();
    }, []);

    const handleActions = (row) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div onClick={() => handleView(row)}>
                    <i className='pi pi-file' style={{color: 'grey'}}></i>
                </div>

                <div onClick={() => handleEdit(row)}>
                    <i className='pi pi-pencil' style={{color: 'yellow'}}></i>
                </div>

                <div onClick={() => handleDel(row)}>
                    <i className='pi pi-trash' style={{color: 'red'}}></i>
                </div>
            </div>
        )
    };

    const handleEdit = (row) => {
        setSId(row.id); setOpen(true);

        setImg(row.image_path==='' ? null : row.image_path); setTitle(row.title)
        setCategory(row.category_name); setDistrict(row.district_name);
        setState(row.state_name); setCId(row.category_id); setDesc(row.description);
        setSTId(row.state_id); setDId(row.district_id); setDate(new Date(row.date))
    };

    const handleDel = (row) => {
        setSId(row.id); setODel(true);
    };

    const submitDel = () => {
        axios({
            method: 'delete',
            url: ARTICLE+'/'+sId,
        }).then((res) => {
            setODel(false); 
            setSId(''); getArticles();
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClose = () => {
        setOpen(false); setSId(''); setTitle(''); setDesc('');
        setImg(null); setCategory(''); setState(''); setCId(''); 
        setDistrict(''); setDId(''); setSTId('');
    };

    const handleView = (row) => {
        axios({
            method: 'get',
            url: ARTICLE+'/'+row.id,
        }).then((res) => {
            setObj(res.data); setOView(true);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleState = (id) => {
        let arr = stateList.filter((it) => it.id === id);
        setState(arr[0].lookup_name); setSTId(id);
    };

    const handleDistrict = (id) => {
        let arr = districtList.filter((it) => it.id === id);
        setDistrict(arr[0].lookup_name); setDId(id);
    };

    const handleCategory = (id) => {
        let arr = categoryList.filter((it) => it.id === id);
        setCategory(arr[0].lookup_name); setCId(id);
    };

    const handleDate = (row) => {
        let dt = new Date(row.date);

        return (dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate()) +'-'+ month[dt.getMonth()] +'-'+ dt.getFullYear()
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {load===true && <Loading />}

            {/* Article List */}
            <Card style={{width: '99%', margin: '5px'}}>
                <DataTable 
                    header={header} removableSort value={articleList} loading={load}
                    scrollable scrollHeight="50vh" paginator stripedRows
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                    rows={10} rowsPerPageOptions={[10,20,50]}
                >
                    <Column field="title" header="Title" sortable />
                    <Column field="description" header="Description" sortable />
                    <Column field="state_name" header="State" sortable />
                    <Column field="district_name" header="District" sortable />
                    <Column field="category_name" header="Category" sortable />
                    <Column header="Actions" body={handleActions} />
                </DataTable>
            </Card>

            {/* Add / Edit Pop-up */}
            <Dialog 
                header={<div>{sId ? 'Edit Article' : 'Add Article'}</div>} 
                visible={open} onHide={handleClose} headerStyle={{backgroundColor: '#fff'}} 
                style={{ width: '90vw', height: 'auto' }}
            >
                <div className='grid nested-grid'>   
                    <div className='col-3'>
                        {(img===null||img==='') ? <label htmlFor='uplod'>
                            <div style={{
                                fontSize: '18px', display: 'flex', alignItems: 'center',
                                border: '2px dashed #ccc', borderRadius: '5px', fontWeight: '600',
                                height: '150px', width: 'auto', justifyContent: 'center',
                            }}>
                                Upload Image
                            </div> 
                        </label> : <div>
                            <div className='pi pi-times-circle' style={{
                                position: 'absolute', color: 'red', 
                                fontSize: '19px', fontWeight: '700', 
                                marginLeft: '162px', marginTop: '5px'
                            }} onClick={
                                () => setImg(null)
                            }>&nbsp;</div>
                            
                            {sId 
                                ? img.name ? <img src={URL.createObjectURL(img)} style={{height: '150px', width: '185px', borderRadius: '5px'}} /> 
                                : <img src={IMG_PATH+img} style={{height: '150px', width: '185px', borderRadius: '5px'}} /> 
                                : <img src={URL.createObjectURL(img)} style={{height: '150px', width: '185px', borderRadius: '5px'}} />
                            }
                        </div>}

                        <input 
                            id='uplod' style={{display: 'none'}} 
                            type='file' accept='image/*'
                            onChange={(e) => {e.target.files ? setImg(e.target.files[0]) : setImg(null)}} 
                        />
                    </div>

                    <div className='col-9'>
                        <div className='grid'>
                            <div className='col-6'>
                                <label style={(err===true&&date===null) ? {color: 'red'} : {}}>Date <sup style={{color: 'red'}}>*</sup></label>
                                <Calendar 
                                    value={date} onChange={(e) => setDate(e.value)} 
                                    dateFormat="dd-mm-yy" style={{width: '100%'}} 
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&title==='') ? {color: 'red'} : {}}>Title <sup style={{color: 'red'}}>*</sup></label>
                                <InputText 
                                    value={title} onChange={(e) => setTitle(e.target.value)} 
                                    style={{width: '100%'}} date
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&desc==='') ? {color: 'red'} : {}}>Description <sup style={{color: 'red'}}>*</sup></label>
                                <InputText 
                                    value={desc} onChange={(e) => setDesc(e.target.value)} 
                                    style={{width: '100%'}} 
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&state==='') ? {color: 'red'} : {}}>State <sup style={{color: 'red'}}>*</sup></label>
                                <Dropdown 
                                    value={stId} onChange={(e) => handleState(e.value)} 
                                    options={stateList} optionLabel='lookup_display_name' 
                                    optionValue='id' style={{width: '100%'}} 
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&district==='') ? {color: 'red'} : {}}>District <sup style={{color: 'red'}}>*</sup></label>
                                <Dropdown 
                                    value={dId} onChange={(e) => handleDistrict(e.value)} 
                                    options={districtList} optionLabel='lookup_display_name'
                                    optionValue='id' style={{width: '100%'}} 
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&category==='') ? {color: 'red'} : {}}>Article Category <sup style={{color: 'red'}}>*</sup></label>
                                <Dropdown 
                                    value={cId} onChange={(e) => handleCategory(e.value)} 
                                    options={categoryList} optionLabel='lookup_display_name'
                                    optionValue='id' style={{width: '100%'}} 
                                />
                            </div>  
                        </div>
                    </div>

                    <div className='col-12' style={{textAlign: 'center'}}>
                        <Button 
                            label='Submit' onClick={() => handleSubmit()}
                            style={{width: '200px'}} disabled={load}
                        />
                    </div>
                </div>
            </Dialog>

            {/* Delete Pop-up */}
            <Dialog 
                visible={opnDel} header={<h3>Do you want to Delete ?</h3>}
                onHide={() => {setODel(false); setSId('')}} headerStyle={{backgroundColor: '#fff'}} 
            >
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div onClick={() => { setODel(false); setSId('') }} style={{color: 'red', fontWeight: '600'}}>No</div>

                    <div onClick={() => submitDel()} style={{fontWeight: '600'}}>Yes</div>
                </div>
            </Dialog>

            {/* View Pop-up */}
            <Dialog 
                visible={opnView} header='View Article' style={{width: '75vw'}}
                onHide={() => {setOView(false); setObj('')}}
            >
                <div className='grid nested-grid'>
                    <div className='col-4'>
                        {sObj.image_path!==null ? <img src={IMG_PATH+sObj.image_path} style={{width: '150px', height: 'auto'}} /> : 'No Image Found'}
                    </div>

                    <div className='col-8'>
                        <div className='grid'>
                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Date</div>
                                <div style={{width: '60%'}}>{sObj.first_name}&nbsp;{handleDate(sObj)}</div>
                            </div>

                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Title</div>
                                <div style={{width: '60%'}}>{sObj.first_name}&nbsp;{sObj.title}</div>
                            </div>

                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Description</div>
                                <div style={{width: '60%'}}>{sObj.description}</div>
                            </div>

                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Category</div>
                                <div style={{width: '60%'}}>{sObj.category_name}</div>
                            </div>

                            <div className='col-6' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>State</div>
                                <div style={{width: '60%'}}>{sObj.state_name}</div>
                            </div>

                            <div className='col-6' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>District</div>
                                <div style={{width: '60%'}}>{sObj.district_name}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default Article;