import React, { useState, useEffect } from 'react';

import { InputMask } from 'primereact/inputmask';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

import axios from 'axios';

import { DOMAIN, IMG_PATH, USERS, USER_TYPE_LIST } from '../apiurls';
import { UserState } from './loginContext';

import Loading from './loading';

const Users = () => {
    const [utList, setUTList] = useState([]);
    const [userList, setUList] = useState([]);

    const [load, setLoad] = useState(false);

    const [sId, setSId] = useState('');
    const [sObj, setObj] = useState('');

    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [pass, setPass] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [img, setImg] = useState(null);
    const [userType, setUType] = useState('');

    const [err, setErr] = useState(false);
    const [open, setOpen] = useState(false);
    const [opnDel, setODel] = useState(false);
    const [opnView, setOView] = useState(false);

    const { user } = UserState();

    const header = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="text-xl text-900 font-bold clr-gold">Users</div>
            
            <Button 
                style={{ width: '100px' }} icon={<i className='pi pi-plus' style={{fontSize: '18px', fontWeight: '600'}}></i>}
                raised severity='warning' onClick={() => setOpen(true)}
                label={<div style={{fontSize: '18px', fontWeight: '600'}}>Add</div>}
            />
        </div>
    );

    const getUsers = () => {
        setLoad(true);

        axios({
            method: 'get',
            url: USERS,
        }).then((res) => {
            setUList(res.data.rows); setLoad(false);
        }).catch((err) => {
            console.log(err);
        });

        axios({
            method: 'get',
            url: USER_TYPE_LIST,
        }).then((res) => {
            setUTList(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleSubmit = () => {
        if(fname && lname && email && pass && mobile && userType && img){
            setErr(false); setLoad(true);

            let obj = {
                first_name: fname,
                last_name: lname,
                email: email,
                password: pass,
                mobile_number: mobile,
                user_role_id: userType,
                updated_by: user.data.id,
            };

            const forms = new FormData();
            forms.append('adminuserImage', img);

            if(sId===''){
                obj.created_by = user.data.id;

                axios({
                    method: 'post',
                    url: USERS,
                    data: obj,
                }).then((res) => {

                    axios({
                        method: 'put',
                        url: USERS+'/'+res.data.data.id,
                        data: forms,
                    }).then((res) => {
                        handleClose(); getUsers();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                axios({
                    method: 'put',
                    url: USERS+'/'+sId,
                    data: obj,
                }).then((res) => {
                    axios({
                        method: 'put',
                        url: USERS+'/'+sId,
                        data: forms,
                    }).then((res) => {
                        handleClose(); getUsers();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            };
        }else{
            setErr(true); setLoad(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleName = (it) => {
        return `${it.first_name} ${it.last_name ? it.last_name : ''}`
    };

    const handleActions = (row) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div onClick={() => handleView(row)}>
                    <i className='pi pi-file' style={{color: 'grey'}}></i>
                </div>

                <div onClick={() => handleEdit(row)}>
                    <i className='pi pi-pencil' style={{color: 'yellow'}}></i>
                </div>

                <div onClick={() => handleDel(row)}>
                    <i className='pi pi-trash' style={{color: 'red'}}></i>
                </div>
            </div>
        )
    };

    const handleEdit = (row) => {
        setSId(row.id); setOpen(true);

        setImg(row.image_path==='' ? null : DOMAIN+row.image_path);
        setPass(row.password); setEmail(row.email);
        setFName(row.first_name); setLName(row.last_name); 
        setMobile(row.mobile_number); setUType(row.user_role_id);
    };

    const handleDel = (row) => {
        setSId(row.id); setODel(true);
    };

    const submitDel = () => {
        axios({
            method: 'delete',
            url: USERS+'/'+sId,
        }).then((res) => {
            setODel(false); 
            setSId(''); getUsers();
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClose = () => {
        setOpen(false); setSId('');
        setImg(null); setFName(''); setLName('');
        setMobile(''); setEmail(''); setPass(''); setUType(''); 
    };

    const handleView = (row) => {
        axios({
            method: 'get',
            url: USERS+'/'+row.id,
        }).then((res) => {
            setObj(res.data); setOView(true);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleUsr = (row) => {
        if(row && utList){
            if(row.user_role_id!==null){
                let arr = utList.filter((it) => it.id === row.user_role_id);
                console.log(row, arr);
        
                return arr[0].lookup_display_name;
            }else{
                return "Super Admin"
            }
        }else{
            return "Super Admin"
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {load===true && <Loading />}

            {/* User List */}
            <Card style={{width: '99%', margin: '5px'}}>
                <DataTable 
                    header={header} removableSort 
                    value={userList} loading={load}
                    stripedRows scrollable scrollHeight='65vh'
                >
                    <Column field="first_name" header="Name" body={handleName} sortable />
                    <Column field="email" header="Email" sortable />
                    <Column field="mobile_number" header="Mobile" sortable />
                    <Column header="Actions" body={handleActions} />
                </DataTable>
            </Card>

            {/* Add / Edit Pop-up */}
            <Dialog 
                header={<div>{sId ? 'Edit User' : 'Add User'}</div>} 
                visible={open} onHide={handleClose}
                headerStyle={{backgroundColor: '#fff'}} style={{ width: '90vw', height: 'auto' }}
            >
                <div className='grid nested-grid'>   
                    <div className='col-3'>
                        {(img===null||img==='') ? <label htmlFor='uplod'>
                            <div style={{
                                fontSize: '18px', display: 'flex', alignItems: 'center',
                                border: '2px dashed #ccc', borderRadius: '5px', fontWeight: '600',
                                height: '150px', width: 'auto', justifyContent: 'center',
                            }}>
                                Upload Profile Image
                            </div> 
                        </label> : <div>
                            <div className='pi pi-times-circle' style={{
                                position: 'absolute', color: 'red', 
                                fontSize: '19px', fontWeight: '700', 
                                marginLeft: '162px', marginTop: '5px'
                            }} onClick={
                                () => setImg(null)
                            }>&nbsp;</div>
                            
                            {sId 
                                ? img.name ? <img src={URL.createObjectURL(img)} style={{height: '150px', width: '185px', borderRadius: '5px'}} /> 
                                : <img src={IMG_PATH+img} style={{height: '150px', width: '185px', borderRadius: '5px'}} /> 
                                : <img src={URL.createObjectURL(img)} style={{height: '150px', width: '185px', borderRadius: '5px'}} />
                            }
                        </div>}

                        <input 
                            id='uplod' style={{display: 'none'}} 
                            type='file' accept='image/*'
                            onChange={(e) => {e.target.files ? setImg(e.target.files[0]) : setImg(null)}} 
                        />
                    </div>

                    <div className='col-9'>
                        <div className='grid'>
                            <div className='col-6'>
                                <label style={(err===true&&fname==='') ? {color: 'red'} : {}}>First Name <sup style={{color: 'red'}}>*</sup></label>
                                <InputText value={fname} onChange={(e) => setFName(e.target.value)} style={{width: '100%'}} />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&lname==='') ? {color: 'red'} : {}}>Last Name <sup style={{color: 'red'}}>*</sup></label>
                                <InputText value={lname} onChange={(e) => setLName(e.target.value)} style={{width: '100%'}} />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&email==='') ? {color: 'red'} : {}}>Email <sup style={{color: 'red'}}>*</sup></label>
                                <InputText value={email} onChange={(e) => setEmail(e.target.value)} style={{width: '100%'}} />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&pass==='') ? {color: 'red'} : {}}>Password <sup style={{color: 'red'}}>*</sup></label>
                                <Password value={pass} onChange={(e) => setPass(e.target.value)} style={{width: '100%'}} toggleMask />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&mobile==='') ? {color: 'red'} : {}}>Mobile <sup style={{color: 'red'}}>*</sup></label>
                                <InputMask value={mobile} onChange={(e) => setMobile(e.value)} style={{width: '100%'}} mask="9999999999" />
                            </div>  

                            <div className='col-6'>
                                <label style={(err===true&&userType==='') ? {color: 'red'} : {}}>User Type <sup style={{color: 'red'}}>*</sup></label>
                                <Dropdown 
                                    value={userType}  style={{width: '100%'}}
                                    options={utList} optionLabel='lookup_display_name' 
                                    optionValue='id' onChange={(e) => setUType(e.value)}
                                />
                            </div>                     
                        </div>
                    </div>

                    <div className='col-12' style={{textAlign: 'center'}}>
                        <Button 
                            label='Submit' onClick={() => handleSubmit()}
                            style={{width: '200px'}} disabled={load}
                        />
                    </div>
                </div>
            </Dialog>

            {/* Delete Pop-up */}
            <Dialog 
                visible={opnDel} header={<h3>Do you want to Delete ?</h3>}
                onHide={() => {setODel(false); setSId('')}} headerStyle={{backgroundColor: '#fff'}} 
            >
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div onClick={() => { setODel(false); setSId('') }} style={{color: 'red', fontWeight: '600'}}>No</div>

                    <div onClick={() => submitDel()} style={{fontWeight: '600'}}>Yes</div>
                </div>
            </Dialog>

            {/* View Pop-up */}
            <Dialog 
                visible={opnView} header='View User' style={{width: '75vw'}}
                onHide={() => {setOView(false); setObj('')}}
            >
                <div className='grid nested-grid'>
                    <div className='col-4'>
                        {sObj.image_path!==null ? <img src={IMG_PATH+sObj.image_path} style={{width: '150px', height: 'auto'}} /> : 'No Image Found'}
                    </div>

                    <div className='col-8'>
                        <div className='grid'>
                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Full Name</div>
                                <div style={{width: '60%'}}>{sObj.first_name}&nbsp;{sObj.last_name}</div>
                            </div>

                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Email</div>
                                <div style={{width: '60%'}}>{sObj.email}</div>
                            </div>

                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>User Type</div>
                                <div style={{width: '60%'}}>{handleUsr(sObj)}</div>
                            </div>

                            <div className='col-12' style={{display: 'flex'}}>
                                <div style={{width: '40%', fontWeight: 'bold'}}>Mobile No.</div>
                                <div style={{width: '60%'}}>{sObj.mobile_number}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default Users;